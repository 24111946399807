@import "./../../../styles/variables.scss";

.basic-info-container {
  display: grid;
  width: 88%;
}

.basic-info-select {
    ul {
      li {
        font-size: 13px !important;
        &:hover {
          background-color: $avatar-background;
          color: $secondary;
        }
      }
    }
  }
  
  .collection-as-connection{
    color: $secondary;
  }

  .basic-info-header{
    font-family: "Poppins";
    font-size: 27px;
    color: $primary;
    font-weight: 600;
    // margin-left: 17px;
    // margin-top: 35px;
  }

  .dynamic-list-input-container{
    display: flex;
    justify-content: flex-start;
    gap: 140px !important;
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 1px; 
  }
