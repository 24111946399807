@import "./../../../../styles/variables.scss";

.column-list-item {
    display: flex;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #333;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: #dae1e9;
    }
}

.connection-icon {
    width: 20px; 
    height: 20px;
    margin-right: 8px; 
    vertical-align: middle;
}
.authorize-button{
    margin-left: auto;    
}