.connection-screen-excel {
    background-color: white;
}

.connection-screen-asheet-logo {
    svg {
        margin-bottom: 1rem;
        margin-top: 1.5rem;
        // height: 42px;
        width: 46px;
        height: 62px;
        // margin-left: 40px;
    }
}

.excel-connection-name {
    font-size: 1rem;
    color: #16365F;
    margin-bottom: -1.5rem;
    margin-top: -6.5rem;
}

.connection-screen-connect-button {
   padding: 0 50px;
   button {
    padding: 0 50px;
   }
}

.excel-connection-screen-button {
    margin: -5px 0;
    font-size: 1rem;
}

.arrow-icon{
    svg {
       font-size: 3.5rem;
       margin-top: 1.3rem;
       height: 50px;
       width: 50px;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1000; /* Ensure it's above other content */
  }
  