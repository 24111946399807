.user-access-log-heading {
  color: #16365f;
  font-weight: bold;
  font-size: 20px;
  font-family: "Poppins";
  margin-bottom: 20px;
}

.user-access-log-container {
  display: flex;
  gap: 3px;
}

.filter-paper {
  width: 300px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.grid-paper {
  flex: 1;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
}

.grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.grid-wrapper {
  width: 100%;
  height: 100%;
}

.grid-content {
  width: 100%;
  height: 650px;
}

.total-rows-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.total-rows-text {
  font-family: "Poppins" !important;
  color: #16365f !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
}

.filter-heading {
  font-size: 16px;
  font-weight: bold;
  color: #16365f;
  margin-bottom: 10px;
  font-family: "Poppins";
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.dropdown-text {
  font-size: 14px;
}

.date-field {
  width: 300px !important;
  font-size: 14px;
}

.filter-options {
  width: 300px !important;
}

.or-text {
  font-size: 16px;
  font-weight: bold;
  color: #16365f;
  text-align: center;
  align-items: center !important;
}

.loading-message {
  position: fixed;
  top: 50%;
  left: 61%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #16365f !important;
  font-weight: bold;
}
