@import "./../../../styles/variables.scss";

.search-textfield {
  span {
    padding: 5px;
  }
  display: flex;
  justify-content: space-between;
}

.search-text-field {
  margin: 1em 1em !important;
  width: 40% !important;
}

.column-table-paper {
  // padding-block-end: 5%;

  th {
    color: $secondary;
    font-weight: $font-weight-semibold;
    font-size: 15px;
    padding: 6px 16px;
    white-space: nowrap;
  }
  td {
    color: $muted;
    font-size: 15px;
    padding: 1px 16px;
  }
  li {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.collection-error-div {
  padding: 1px 1em 0em 1em;
}

.display-name-input input {
  width: 300px !important;
  height: 34px !important;
  padding: 0 10px !important;
}

.edit-column-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.display-format {
  width: 100%;
}

.display-format-select {
  ul {
    li {
      font-size: 13px !important;
      &:hover {
        background-color: $avatar-background;
        color: $secondary;
      }
    }
  }
}

.row-selected {
  background-color: $selected-row-color;
}

.clear-icon {
  color: $primary;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: $avatar-background;
  }
}

.alternative-div {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #1976d2;
  color: #7b6be1;
}

.custom-table-cell {
  padding: 0px 16px 0px 16px !important;
}

.drag-icon-table-cell {
  padding: 0px 0px 0px 0px !important;
}

.smooth-transition {
  transition: transform none
  background-color 0s ease-in-out;}

.dragged {
  box-shadow: none; 
  opacity: 0.9;
}

.dragged-row {
  width: 100%;
}

.drag-placeholder {
  display: table-row;
  visibility: hidden;
}
