.hi-menu-icon {
    color: black;
    height: 25px;
    width: 25px;
    margin: 0px 0px 5px -10px;
    cursor: pointer;
}
.plugin-back-button {
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 5px;
}
.plugin-asheet-logo{
    height: 35px;
    width: 35%;
    position: absolute;
    right: 0px;
    top: -8px;
}