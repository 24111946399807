.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.not-found-body {
  text-align: center;
}

.oops-heading {
  font-size: 100px; /* Adjust the font size as needed */
}

.not-found-button-div{
    display: flex;
    justify-content: flex-end;
    column-gap: 12px;
}

.go-back-link{
    margin-top: 6px;
    text-decoration: none;
}

.unauthorized-resource-button-div{
  display: flex;
  justify-content: center;
  column-gap: 12px;
}