@import "./../../../styles/variables.scss";

.support-view-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 70px 0px;
    background: $block-background;
    height: 60vh;
}
.support-view-card {
    height: 210px;
    margin: auto;
    background: $white;
    text-align: center;
    position: relative;
    padding: 15px 30px;
    box-shadow: $box-shadow;
    border-radius: 5px;
    width: 660px;
}
.support-view-button{
    text-align: left !important;
    margin-top: 40px;
}
.support-view-header {
    margin-top: 10px !important;
    display: grid;
    margin-bottom: 5px;
    color: #16365F;

}
.support-view-header label{
    text-align: start;
    font-weight: 700;
    font-size: 21px;
}
.support-view-text{
    display: flex;
    justify-content: left;  
    font-size: 16px;  
}
.support-view-text span{
    font-weight: 700;
    margin: 0px 5px;
}