.header-part{
    display: flex;
    color: #16365F;
    font-size: 16px;
}
.plan-description {
    margin-right: 20px;
    margin-top: 5px;

    &.free-subscription {
        margin-right: 40px;
    }
}
.current-plan-label{
    font-style: italic;
}
.content-in-line{
    display: flex;
    justify-content: space-between;
}
.content-in-line-without-jc{
    display: flex;
}
.bold-plan-name{
    padding-right: 10px;
    font-size: 18px;
    font-weight: bold;
}
.bold-price-name{
    font-weight: bold;
    margin-left: 5px;
}
.bold-user-number{
    font-weight: bold;
    margin-right: 5px;
}
.peryear-text{
    font-style: italic;
}
.verticleLine{
    height: auto;
    width: 1px;
    margin: 0 40px 0 20px;
    background-color: #16365F;
    margin-top: 22px;
}
.upgrade-title{
    font-size: 21px;
    font-weight: bold;
}
.title-part{
    margin-top: 22px;

    &.free-subscription {
        margin-left: 40px
    }
}
.subscription-date-range{
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
}