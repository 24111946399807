@import "./../../../styles/variables.scss";

.test-connection-paper {
  min-height: 100px;
  width: 750px;
  padding: 20px;
  p {
    color: $secondary;
  }
}
.test-connection-paper-tally{
  min-height: 100px;
  width: 850px;
  padding: 20px;
  p {
    color: $secondary;
  }
}

.test-connection-div {
  text-align: end;
  margin-top: 30px;
  button {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12) !important;
    border: none !important;
  }
}

.test-tally-link-div{
  display: flex;
  margin-top: 15px;
  column-gap: 10px;
  span{
    font-size: 17px;
  }
}
.tally-link{
  color: #1922F2 !important;
  font-size: 14px;
}

.tally-connector-test-message-part{
 width: 70%;
}

.tally-connector-test-content{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    span{
      font-size: 16px;
    }
}
.tally-test-result-div{
  margin-top: 18px;
}
.tally-test-connection-btn-div{

  button{
    padding: 5px 24px;
    font-size: 16px;
  }
}
.test-connection-btn {
  text-align: end;
  margin-top: 30px;
}

.connecting-dots:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.connecting-spinner-div {
  display: flex;
  position: relative;
  p {
    margin: 8px 0 0 28px !important;
  }
}

$duration: 8.5s;
$size: 5px;

.connecting-spinner {
  position: absolute;
  top: 15px;
  left: 8px;
  height: $size;
  width: $size;

  > .spinner-dot {
    position: absolute;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: $button-primary;
    animation: rotateDots 4s linear infinite;

    &:nth-of-type(1) {
      top: -$size;
      left: -$size;
      animation-delay: -6 * $duration / 8;
    }

    &:nth-of-type(2) {
      top: -$size;
      left: 0;
      animation-delay: -5.9 * $duration / 8;
    }

    &:nth-of-type(3) {
      top: -$size;
      left: $size;
      animation-delay: -5.8 * $duration / 8;
    }

    &:nth-of-type(4) {
      top: -$size;
      left: $size + $size;
      animation-delay: -5.7 * $duration / 8;
    }

    &:nth-of-type(5) {
      top: 0;
      left: $size + $size;
      animation-delay: -5.6 * $duration / 8;
    }

    &:nth-of-type(6) {
      top: $size;
      left: $size + $size;
      animation-delay: -5.5 * $duration / 8;
    }

    &:nth-of-type(7) {
      top: $size + $size;
      left: $size + $size;
      animation-delay: -5.4 * $duration / 8;
    }

    &:nth-of-type(8) {
      top: $size + $size;
      left: $size;
      animation-delay: -5.3 * $duration / 8;
    }

    &:nth-of-type(9) {
      top: $size + $size;
      left: 0;
      animation-delay: -5.2 * $duration / 8;
    }

    &:nth-of-type(10) {
      top: $size + $size;
      left: -$size;
      animation-delay: -5.1 * $duration / 8;
    }

    &:nth-of-type(11) {
      top: $size;
      left: -$size;
      animation-delay: -5 * $duration / 8;
    }

    &:nth-of-type(12) {
      top: 0;
      left: -$size;
      animation-delay: -4.9 * $duration / 8;
    }
  }
}

@keyframes rotateDots {
  0% {
    transform: rotate(0) scale(1);
    animation-timing-function: ease-in;
  }
  10% {
    transform: rotate(90deg) scale(0);
  }
  50% {
    transform: rotate(90deg) scale(0);
    animation-timing-function: ease-out;
  }
  60% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(180deg) scale(1);
  }
}
