@import " ./../../../../../../src/styles/variables.scss";

.proxy-title{
    margin-top: 10px !important;
    color: $primary !important;
    font-weight: 550 !important;
    font-size:30px !important ;
    padding-left: 35px !important;
}
.proxy-dialog-ok-button button{
    text-transform: none !important;
    border-radius: 20px !important;
    padding: 3px !important;
    width:130px !important;
    height:40px !important;
}

.buttons{
    margin-top: 15px !important;
}
.proxy-dialog-cancel-button button{
    background-color: white !important;
    color: #1922F2 !important;
    text-transform: none !important;
}