// @import "../../styles/variables.scss";

.error-div {
  font-size: 16px;
  padding: 2% 5%;
  margin: 4% 0;
  background-color: #f9fbe8;
  color: #6b859e;
  border-radius: 5px;
}

.test-data-error-div {
  font-size: 16px;
  padding: 1% 5%;
  background-color: #f9fbe8;
  color: #6b859e;
  border-radius: 5px;
}

.less-gap-error-div {
  font-size: 16px;
  padding: 2% 5%;
  margin: 2% 0;
  background-color: #f9fbe8;
  color: #6b859e;
  border-radius: 5px;
}
