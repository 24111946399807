@import "./../../../styles/variables.scss";
.acceptinvitation-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.acceptinvitation-popup {
  margin: 0 auto;
  margin-block-start: 5%;
  margin-block-end: 5%;
  height: -moz-fit-content;
  height: fit-content;
  width: 32%;
  padding: 30px 3% 35px 3%;
  border-radius: 10px;
  position: relative;
  min-height: 500px;
  background-color: $white;
  box-shadow: $paper-box-shadow;
}
.acceptinvitation-popup-userexists {
  margin: 0 auto;
  margin-block-start: 5%;
  margin-block-end: 5%;
  height: -moz-fit-content;
  height: fit-content;
  width: 25%;
  padding: 35px 4% 40px 4%;
  border-radius: 10px;
  position: relative;
  min-height: 300px;
  background-color: $white;
  box-shadow: $paper-box-shadow;
}
.headeading {
  color: #000000;
  margin-top: 10px;
  font-weight: 500;
}
.login-check-heading {
  color: #000000;
  margin-top: 20px;
  font-weight: 600;
}
.sub-headeading {
  margin-bottom: 20px;
  margin-top: 6px;
  font-weight: $font-weight-semibold;
  font-size: 20px;
  color: $primary;
}
.button-secondary{
  margin-right: 20px;
}
.login-button {
  text-align: center;
  margin-top: 3%;

  .link {
    color: $button-primary-text !important;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: capitalize;
    text-decoration: none;
    padding: 0%;
  }
}
.login-button-userexists {
  text-align: center;
  margin-top: 20%;

  .link {
    color: $button-primary-text !important;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: capitalize;
    text-decoration: none;
    padding: 0%;
  }
}
.login-button-userexists button {
  text-transform: capitalize;
  border-radius: 25px;
  padding: 2% 10%;
  font-weight: 600;
  background-color: $button-primary !important;
  &:hover {
    background-color: $button-primary-hover !important;
  }
}
.padding20 {
  padding-top: 20px;
}
.login-button button {
  text-transform: capitalize;
  border-radius: 25px;
  padding: 2% 10%;
  font-weight: 600;
  background-color: $button-primary !important;
  &:hover {
    background-color: $button-primary-hover !important;
  }
}
.columns-column-1 {

  height: 93%;
  text-align: center;
}
.error-msg {
  margin-bottom: 10px;
  font-weight: $font-weight-semibold;
  width: fit-content;
  text-align: center;
  height: fit-content;
  background-color: $block-background;
  height: 30;
  padding: 1px 20px 1px 20px;
}
.error-msg p {
  margin-top: 8px;
  color: $secondary;
}
.as-form-spinner {
  width: -webkit-fill-available;
  height: 6px;
  background: linear-gradient(90deg, #0001 60%, #16365F 65%, #0001 70%) #e0cc4d;
  background-size: 300% 100%;
  animation: pos 1s infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px 10px 0px 0px;
}