// .app-container {
//   background-color: white;
// }
.app-container-excel{
  height: 95vh;
  width: 100%;
}

.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 76vh; /* Set full viewport height */
  padding: 0px 5px;
  margin-bottom: -5px;
}

.sidebar {
  padding: 20px;
  background-color: #fff;
  border-right: 1px solid #ddd;
}

.sidebar.open {
  padding: 20px;
  background-color: #fff;
  border-right: 1px solid #ddd;/* Same width as when closed */
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
}

.sidebar-label {
  margin: 5px 0 -15px -5px;
  font-size: 13px;
  color: black;
  // font-weight: bold;
}

.list-item {
  padding: 10px 20px;
  cursor: pointer;
}

.list-item-icon {
  min-width: 40px;
}

.list-item-text {
  font-size: 16px;
}


.welcome-message {
  font-size: 24px;
  font-weight: bold;
}

.no-connection-message-2 {
  margin-bottom: 8px;
}

.excel-connect-button-text {
  padding: 0 14px;
  font-size: 13px;
  text-transform: none; 
  font-weight: 600;
}

.asheet-logo-welcome-excel {
  svg {
    height: 22px;
    margin-left: -7px;
    margin-top: 10px;
  }
  
}

.excel-connection-type {
  color: #16365F;
  font-size: 13px;
  margin-left: -60px;
}

.excel-connection-description {
  color: #16365F;
  font-size: 13px;
}
