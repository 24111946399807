@import "./../../../styles/variables.scss";

.plan-discription-text {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 670px !important;
}

.margin-left-view {
    margin-left: 50px;
    margin-bottom: 30px;
}

.plan-heading {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid $muted;
    width: 660px !important;
}
.consultant-amount{
    width: 20% !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    display: block;
    margin-left: 72.4px;
    color: $secondary !important;
}

.totalSummary-view {
    display: flex !important;
    margin-top: 5px;
    justify-content: flex-end;
    //margin-right: 20px !important;
}