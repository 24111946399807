.sort-fiels-group-part{
    width: 50%;
}

.sort-condition-group-part{
    width: 50%;
}

.sort-field-label-div{
    width: 50%;
}