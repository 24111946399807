.title{
    font-weight: 600 !important;
    font-size:30px !important ;
    padding-left: 35px !important;
}

.buttons{
    display: flex;
    justify-content: end;
    position: relative;
    top: 10px;
    gap: 20px;
}
.save-organization-error{
    padding: 1px 2em 0em 2em;
    margin: -2em 0px;
}

.cancel-button{
    color: rgba(137,122,228,1) !important;
    text-transform: none !important;
    text-decoration: none !important;
}

.accept-button{
    background-color: rgba(255,86,104,1) !important;
    color: white !important;
    border-radius: 5px !important;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
    text-transform: none !important;
    text-decoration: none !important;
    width: 95px !important;
    font-weight: 600 !important;
}