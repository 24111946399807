@import " ../../../../../../src/styles/variables.scss";

.collection-nav-container {
    width: 100%;
    display: flex;
    position: sticky;
    top: 74px;    
    white-space: nowrap; 
    background-color: $block-background;
    z-index: 1000;
    padding: 6px 0px 0px 0px;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 2px;
    }

    .collection-nav-item {
        font-size: 12px;
        padding: 7px 10px 7px 10px;
        min-height: 22px;
        color: $muted;
        cursor: pointer;
        text-transform: none; 
    }
}

.mui-tabs {
    min-height: 31px !important;
    .MuiTabs-indicator {
        background-color: $primary;
    }
    .Mui-selected {
        font-weight: bold;
        color: $primary-button !important;
    }
}

