@import "./../../../styles/variables.scss";

.column-selection-list-container{
    // width: 25%;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #f9fbe8;
    overflow-y: auto;
   
}

.column-selection-list-name{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-left: 19px;

    span{
        color: $secondary;
        font-size: 16px;
    }
}

.column-selection-header{
    font-weight: $font-weight-bold;
    font-family: "Poppins";
}

.column-count-div{
    font-family: sans-serif;
    color: $secondary;
}

.column-search-input-div{
    width: 100%;
    display: flex;
    justify-content: center;
    
    input{
        background: white;
        padding: 12.5px 14px !important;

    }

    label{
        font-size: 17px;
        top: -6px !important;
    }

    .MuiInputLabel-shrink {
        top: 0px !important;
    }
}

.column-search-input{
    width: 90%;
}

.checkbox-column{
    padding: 10px;
    font-family: "Poppins";
    font-size: 16px;    
    padding-left: 15px;

    span{
        padding-right: 7px;
        font-size: 15px;
        padding-bottom: 1px;
        padding-top: 1px;
    }

    svg{
        font-size: 19px;
    }
}

.column-list-view{
    display: grid;
    margin-top: 5px;
}

.checkbox-column-item2{
    padding-left: 0px !important;
    padding-right: 0px !important;    
}

.checkbox-column-item{
    padding: 2px;
    padding-left: 16px;
    font-family: "Poppins";
    font-size: 12px;

    span{
        padding-right: 7px;
        font-size: 15px;
        padding-bottom: 1px;
        padding-top: 1px;
    }

    svg{
        font-size: 19px;
    }
}

.column-list-wrapper {
    flex-grow: 1;    
    overflow-y: auto; 
}