@import "./../../../styles/variables.scss";


.add-new-link {
    text-decoration: none !important;
    color: #fff !important;
}

.script-paper-group {
    margin-top: 20px !important;
}

.script-btn-div {
    width: auto;
    display: flex;
    border-radius: 10px;
    margin-right: 20px;
}

.main-script-div {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
}

.right-view-div {
    width: 80%;
}

.left-view-div {
    margin-top: 50px;
    width: 19%;
}

.top-view-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.script-name-div {
    display: grid;
}

.script-view-btn {
    width: 100%;
    display: flex;
    justify-content: end;
}

.script-button-group-container .MuiButtonGroup-root {
    display: flex;
    width: 150%;
}

.script-button-group-container .MuiButton-root {
    text-transform: none;
    flex: 1;
    border-color: #cbcbcb;
    padding: 8px 0px 8px 0px;
    color: #16365F;
    font-size: 16px;
}

.script-button-group-container .MuiButton-root.selected {
    background-color: #dae1e9;
}
.scriptTableHeight{
    height:75vh;
}