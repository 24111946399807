.connect-view-div {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
}

.connect-button-div button{    
    width: 180px;
    box-shadow: 0px 2px 5px grey;
    text-transform: none;
    border-radius: 10px;
    margin-top: 20px;
    padding: 3px;
}

.connect-button-div{
    display: flex;
    justify-content: center;
}

.connect-view-div label{
    padding-left: 5px;
    font-size: 15px;
    justify-content: center;
    display: flex;
}

.arrow-icon-div{
    margin : 5px 0px 0px 10px;
    height: 60px;
    width: 60px;
}

.logo-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.connection-logo{
    margin-top: 10px;
    height: 50px;
    width: 50px;
}