@import "./../../../styles/variables.scss";

.card-container-type {
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
  .card {
    min-width: "261px";
  }
  max-height: 270px;
  overflow-y: auto;
  padding-bottom: 10px;
}
.card-container-type-cloud {
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
  .card {
    min-width: "261px";
  }
  max-height: 315px;
  overflow-y: auto;
  padding-bottom: 10px;
}

.card-action-area {
  display: flex !important;
  height: 100% !important;
  justify-content: flex-start !important;
  padding-inline-start: 15px !important;
  padding-block: 7px !important;
  position: relative;
}

.connection-name {
  color: $secondary;
}

.connection-card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px !important;
}

.connetion-type-label{
  color: #16365F!important;
  margin-top: 2px;
  margin-left: 3px;
  font-weight: 600!important;
  font-size: 16px;
  margin-bottom: 5px;
}

.crown-div{
  position: absolute;
  top: 6px;
  right: 13px;
  display: flex;
  column-gap: 7px;
  img{
    height: 12px;
  }
}

.main-connection-type-div{
  position: relative;
}
.search-connector-input{
  position: absolute;
  top: -36px;
  right: 0;
  width: 30%;
  height: 42px;
}
.connector-search-box{
  padding: 0;
  font-size: 10px;
  input{
    padding: 13.5px 11px;
    background: white;
  }
}