.prompt-popup {
  .tab-button {
      padding: 3px 8px;
      color: #424242;
      border: 1px solid #bdbdbd;

      &:hover {
          background-color: #bdbdbd;
          border-color: #9e9e9e;
      }

      &.active {
          background-color: #e0e0e0;
          border-color: #757575;
      }
  }
}

.response-container {
    .loader-animation {
      font-size: 14px;
      color: #424242;
      animation: loaderFade 1.5s infinite ease-in-out;
    }
  }
  
  @keyframes loaderFade {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }