@import "./../../../styles/variables.scss";

.parameter-ok-button {
  button {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
    padding: 6px 16px;
  }
}
.collection-ok-btn {
  button {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
    padding: 6px 16px;
    color: #fff !important;
    background-color: $primary-button !important;
  }
}
.ok-btn-div {
  display: flex;
  justify-content: flex-end;
  margin-block: 20px;
}

.ok-btn {
  margin-left: 30px;
}

.width-75 {
  width: 78%;
  margin-top: 15px;
}

.value-dropdown {
  margin: 1em 0px 1em 20px !important;
}

.dynamic-list-div {
  display: flex;
}

.add-collection-btn-div {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2%;
  display: flex;

  button:hover {
    background-color: transparent;
  }
  button:focus {
    background-color: transparent;
  }
}

.default-label {
  font-size: 11px;
}

.coll-parameter-table {
  th {
    font-weight: $font-weight-semibold;
    color: $text-2;
    padding: 12px 16px !important;
    white-space: nowrap;
  }
  td {
    color: $text-2;
    padding: 3px 16px !important;
  }
  tb{
    padding: 0px !important;
  }
}

.edit-delete-btn-div {
  white-space: nowrap;
}
.selected-opt {
  background-color: $primary !important;
  color: white !important;
  font-size: 13px !important;
}

.autocomplete-option {
  font-size: 13px !important;
  &:hover {
    background-color: $avatar-background !important;
    color: $secondary !important;
    cursor: pointer !important;
  }
}

.w-1250 {
  width: 1250px !important;
}

.connection-name{
  font-weight: 600;
  margin-left: 4px;
}
