@import "./../../../styles/variables.scss";

.columns-selection-container{
    width: 100%;
    height: 100%;
}

.columns-selection-content-container{
    display: flex;
    width: 100%;
    height: 87%;
}

.columns-selection-content-container-notfordialog{
    display: flex;
    width: 100%;
    height: 90vh;
}

.change-link{
    color: #4D53E0;
    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;
    text-decoration: none;
}

.filter-sort-div{
    display: flex;
    justify-content: space-between;
    width: 30%;
}

.filter-container{
    width: 49%;
    position: relative;
}

.sort-container{
    width: 49%;
    position: relative;
    height: 100%;
}

.data-table-div{
    height: 100%;
    width: 75%;
    margin-left: 1%;
    margin-right: 1%;
}

.data-table-main-div{
    height: 88%;
    overflow: hidden;
    position: relative;
}

.refresh-button-div{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(209, 209, 209, 0.25);
    display: flex;
    justify-content: center;
    flex-direction: column;
 
    button{
        width: fit-content;
        height: fit-content;
        margin: auto;
    }
}

.add-icon-header{
    position: absolute;
    right: 0;
    cursor: pointer;
}

.row-number-div{
    display: flex;
    justify-content: end;
}

.row-count-div{
    font-family: "Poppins";
    color: #16365F;
    font-weight: 700;
    font-size: 15px;
}

.add-sort-modal{
    position: absolute;
    width: 270px;
    height: auto;
    background-color: #ececec;
    right: 0;
    top: 21px;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0.5, 0.5, 0.5, 0.5);
    border-radius: 10px;
}

.add-filter-modal{
    position: absolute;
    width: 330px;
    height: auto;
    background-color: #ececec;
    right: 0;
    top: 21px;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0.5, 0.5, 0.5, 0.5);
    border-radius: 10px;
}

.sort-modal-input-div{
    display: flex;
    justify-content: start;
    column-gap: 10px;
    margin-top: 10px;
}

.filter-modal-input-div{

}

.sort-modal-button-div{
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    height: 30px;
}

.sort-data-div{
    margin-left: 10px;
    height: 100%;
    overflow-y: auto;
}


.button-group-container {
    display: flex;
    margin-bottom: 10px; 
    margin-left: 10px;
}

.button-group-container .MuiButtonGroup-root {
    display: flex;
    width: 100%;
}

.button-group-container .MuiButton-root {
    text-transform: none;
    flex: 1;
    border-color: #f5f3f3;
    padding: 8px 0px 8px 0px;
    color: #16365F;
    font-size: 16px;
    // font-family: 'Roboto', sans-serif;
}

.button-group-container .MuiButton-root.selected {
    background-color: #dae1e9;
    // border: 1px solid #f5f3f3 !important;
    font-weight: 700;
}

.button-group-container .MuiButton-root:hover {
    border-color: #f5f3f3;
}

.vertical-container {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.search-option-container{
    height: 100%;
    background: #f9fbe8;
    overflow-y: auto;
}

.search-option-refresh-button{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 13px;
    margin-bottom: 15px;

    button{
        width: fit-content;
        height: fit-content;
        margin: auto;
        padding: 6px 20px 6px 20px;
        box-shadow: 2px 2px 6px rgba(112, 112, 112, 1);
    }
}

.add-script-link{
    color: #4D53E0;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    text-decoration: none;
}

.data-script-button-group-container {
    display: flex;
    margin-bottom: 2px; 
}

.data-script-button-group-container .MuiButtonGroup-root {
    display: flex;
    width: 100%;
}

.data-script-button-group-container .MuiButton-root {
    text-transform: none;
    border-color: #f5f3f3;
    padding: 8px 12px 8px 12px;
    color: #16365F;
    font-size: 16px;
}

.data-script-button-group-container .MuiButton-root.selected {
    background-color: #dae1e9;
    // border: 1px solid #f5f3f3 !important;
    font-weight: 700;
}

.data-script-button-group-container .MuiButton-root:hover {
    border-color: #f5f3f3;
}

.add-script-edit-remove-link{
    color: #4D53E0;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
}

.collection-label {
    color: #16365F !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    font-family: "Poppins" !important;
    padding: 5px 0px 3px 16px;
}