@import "./../../styles/variables.scss";

.setting {
  margin-top: 12px;
}

.card-container {
  display: flex;
  gap: 45px 28px !important;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 36px;
}

.new-link {
  text-decoration: none;
  color: $white;
}

.card-link {
  color: $primary;
}

.setting-card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px !important;
}

.setting-name {
  //width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
