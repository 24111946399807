.limit-add-condition-value-div{
    width: 63%;
}

.limit-add-filter-modal{
    position: absolute;
    background-color: #F7F8FB;
    z-index: 10;
    box-shadow: 0 0 10px rgba(1, 1, 1, 0.5);
    border-radius: 10px;
    top: 30px;
    right: 0px;
    width: 400px;
    padding: 8px 10px;

    label{
        color: #16365F !important;
    }

    input{
        padding: 2.5px 6px !important;
        width: 100%;
    }
}

.limit-fields-group{
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // gap: 10px;
    padding: 10px;
}

.limit-field-label-div{
    display: flex;
    width: 50%;
}