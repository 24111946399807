.succesful-connection-message {
    font-size: 13px;
    text-align: center;
    margin-top: -10rem;
    font-weight: 500;
    color: #16365F;
    font-weight: 500;
}

.disconnect-button {
    text-transform: none !important;
    font-size: 12px !important;
    margin: 1rem 4rem !important;
    font-weight: 550 !important;
    color: #4D53E0 !important;
}