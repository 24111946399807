.asheet-logo-excel{
    margin-left: 10px;
    svg{
        height: 42px;
        // padding-left: 50px;
        margin-bottom: 2.5px;
        margin-right: -18px;
    }
}

.excel-layout-header {
    background-color: #ffffff;
    border-bottom: 0.1px solid #ccc; 
    box-shadow: none; 
    color:black; 
    min-height: 50px;
    margin-top: -10px;
}

