/** @format */

.rotate-90 {
  transform: rotate(90deg);
}

.large-size {
  font-size: 40px !important;
}

.flex-div-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
