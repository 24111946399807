
.flex {
    display: flex;
    align-items: center;
    justify-self: start;
    gap: 8px;
  
    button {
      background-color: white !important;
      padding: 1px 4px !important;
      border: 1px solid gray;
      margin-left: 30px
    }
}

.border-bottom {
    border-bottom: 1px solid #80808061;
    margin-bottom: 7px;
}

.source {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    // cursor: pointer;
  
    span {
      font-size: 10px;
      font-style: italic;
      margin-left: 5px;
    }
  }
  
.source:hover {

background: #00000017;
}

.source img {
width: 20px;
height: 20px;
margin-right: 10px;
}

.plugin-collections-info-icon {
    width: 16px !important;
    position: absolute;
    right: 10px;
}

.content div.active {
    display: grid;
    gap: 8px;
}

.connction-list {
    display: flex;
    align-items: center;
    gap: 7px;
    padding-left: 12px;
    // cursor: pointer;
  
    svg {
      height: 12px;
      width: 12px;
    }
  
    img {
      height: 12px;
      width: 12px;
    }
}

.f-9 {
    font-size: 9px;
}

.plugin-menu-icon {
    position: absolute;
    visibility: hidden;
    cursor: pointer;
    svg {
      left: 15rem;
      position: relative;
      bottom: 1.7rem;
    }
}

.connction-list:hover {
    background: #00000017;
  }
  
.connction-list:hover .plugin-menu-icon {
    display: inline-block;
    visibility: visible;
}

.add-new-connection-btn {

    button {
      margin: 30px !important;
      width: 80% !important;
      border-radius: 10px;
      height: 1.8rem;
    }
}

.plugin-menu-container {
    position: absolute !important;
    top: -27px !important;
    left: 82px !important;
}


.plugin-menu-container-data {
  position: relative !important;
  min-height: 32px !important; 
  padding: 4px 21px 0px !important;
  font-size: 13px !important;
  color: #16365F !important;
}

.plugin-menu-container-data:hover {
  color: #16365F !important;
}

.plugin-collections-content {
  margin-top: 0.7rem;
}
  
  