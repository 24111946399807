@import "../../../src/styles/variables.scss";

.mui-dialog-popup {
    h2 {
      color: $secondary;
      padding: 16px 24px 0px 24px !important;
      margin-bottom: 4%;
    }
  
    p {
      color: $secondary;
      border: 1px solid #707070;
      border-radius: 5px;
      padding: 5px 20px;
      white-space: nowrap;
      text-align: center;
      background: $avatar-background;
      color: $secondary;
      font-weight: $font-weight-semibold;
      font-size: 20px;
      letter-spacing: 8px;
    }
  }
  
  .mui-dialog-popup [role="dialog"] {
    padding: 10px 20px !important;
  }

  .popup-buttons-div button {
    text-transform: capitalize;
    border-radius: 5px;
    padding: 8px 5%;
    margin-left: 25px !important;
    min-width: 100px;
    font-weight: 600;    
  }
  
  .popup-buttons-div {
    padding: 0px 24px 25px 24px !important;
  }

  .first-button {
      color: #16365F !important;
  }

  

  .second-button {
      background-color: #ff5668 !important;
      color: #fff !important;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;   
  }

  .second-button:hover {
      background-color: #16365F !important;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
      rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  }