@import "./../../../styles/variables.scss";

.input-field-box {
  background-color: rgb(255, 255, 255);
  padding: 10px 20px 20px 20px;
  max-height: 650px;
  min-height: 320px;
  overflow: auto !important;
}

.columns-div{
  display: flex; 
  justify-content: space-between;
  padding: 0px 20px;
}
.user-defined-list{
  padding: 0px 20px 0px 20px;
  display: flex;
}
.user-defined-dropdown{
  width: 325px;
}
.columns-input{
  width: 220px;
}
.input-padding {
  column-gap: 50px;
  align-items: center;
  display: flex;
  padding: 0px 20px 0px 20px;
}
.collection-error-div{
  margin: -2% 0px !important;
}
.button-flex {
  margin-right: 20px;
  column-gap: 30px;
  display: flex;
  justify-content: flex-end;
  margin-top: 4%;
}

.add-value-btn {
  margin-left: 72%;
  width: 112px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 3px 5px 3px 0px;
  cursor: pointer;
  right: 101px;
  color: $primary-button;
  text-align: end;
  //position: absolute;
}
.add-icon{
  font-size: 1em;
  font-style: normal;
  padding-right: 5px;
}
.bolean-box{
  display: flex;
  padding: 0px 20px 0px 8px;
}
.edit-value-btn {
  width: 110px;
  margin-left: 25px;
  margin-top: 28px;
  height: 25px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 3px 5px 3px 0px;
  cursor: pointer;
  right: 101px;
  color: $primary-button;
  text-align: end;
  //position: absolute;
}
.input-width {
  width: 448px;
}
.border-icon {
  cursor: pointer;
  width: 20px;
}
.input-field-box-padding {
  position: relative;
}
.scrool {
  overflow-y: scroll;
  overflow-x: none;
  max-height: 276px;
}
.variabe-header {
  font-family: "Poppins";
  font-size: 27px;
  color: $primary;
  font-weight: 600;
  margin-left: 17px;
  margin-top: 10px;
}
.text-field.no-spinner input[type="number"]::-webkit-inner-spin-button,
.text-field.no-spinner input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-field.no-spinner input[type="number"] {
  -moz-appearance: textfield;
}

.user-define-input{
  margin-bottom: 0px !important;
}
.variable-editable-div{
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 8px;
}