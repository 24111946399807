@import "./variables.scss";
.font-weight-bold {
  font-style: normal;
  font-weight: bold !important;
}
.d-flex {
  display: flex;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: end;
}
.align-items-center {
  align-items: center;
}
.text-items-center {
  text-align: center;
}
.text-items-left {
  text-align: left;
}
.flex-wrap {
  flex-wrap: wrap;
}
.pointer {
  cursor: pointer;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-100 {
  width: 100%;
}
.mb-2 {
  margin-bottom: 2%;
}
.px-2 {
  padding: 0 2%;
}
.py-2 {
  padding: 2% 0;
}
.pe-2 {
  padding-right: 2%;
}

.border-bottom {
  border-bottom: 1px solid $info;
}

.MuiAutocomplete-popper {
  box-shadow: $popper-box-shadow;
}
