@import "./../../../styles/variables.scss";

.mainDiv {
    display: flex;
    width: 100%;
 }
.rightDiv {
    width: 70%;
    margin: 30px
}
.update-checkout-div{
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.leftDIv {
    margin-left: 35px;
    background: linear-gradient(to bottom, $primary-button, #2c3e50);
    min-height: 100% !important;
    height: 100vh;
    display: block;
    padding:0px 10px ;
    color: white;
}
.display-contact {
    padding-top: 20px;
    margin-left: 20px;
    margin-bottom: 50px;
}
.logo img{
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.asheet-logo{
    padding-left: 8px;
}
.display-contact  label{
    font-size: 16px;
    font-weight: normal;
}

.display-contactus-text{
   font-size: 20px;
   font-weight: bold;
}
.description-section{
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 15px;
  font-size: 18px;
  font-weight: normal;
}

.description-section label {
   font-size: 20px;
   font-weight: normal;
   margin-bottom: 50px;
}

 @media screen and (min-width: 1250px) {
    /* CSS styles to apply when the screen width is 600px or less */ 
    .leftDIv {
      width: 350px !important;
    }
    .rightDiv{
        margin: 15px;
    }
  }
 @media screen and (min-width: 1400px) {
    /* CSS styles to apply when the screen width is 600px or less */ 
    .leftDIv {
      width: 450px !important;
    }
    .rightDiv{
        width: 65%;
        margin: 22px
    }
    .logo img {
        width: 95%;
    }
  }
  @media screen and (min-width: 1600px) {
    /* CSS styles to apply when the screen width is 600px or less */ 
    .leftDIv {
      width: 500px !important;
    }
    .rightDiv{
        width: 60%;
    }
    .logo img {
        width: 93%;
    }
  }
  @media screen and (min-width: 1700px) {
    /* CSS styles to apply when the screen width is 600px or less */ 
    .leftDIv {
      width: 550px !important;
    }
    .rightDiv{
        margin: 50px
    }   
  }
  @media screen and (min-width: 1900px) {
    /* CSS styles to apply when the screen width is 600px or less */ 
    .leftDIv {
      width: 600px !important;
    }
    .rightDiv{
        margin: 100px;
        width: 65%;
    }
    .logo img {
        width: 95%;
    }
  }