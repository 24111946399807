.main-list-heading {
    font-size: 13px;
    margin-left: 15px;
    margin-top: 10px;
}

.connection-heading {
    font-size: 13px;
}

.connection-image {
    height: 20px;
    width: 20px;
    padding: 0px 5px 0px 0px;
}

.status-text-span {
    font-size: 12px;
    margin-left: 5px;
    font-style: italic;
}

.list-icons {
    display: flex;
}

.list-row-view {
    display: flex;
    align-items: center;
}

.list-row-view:hover {
    .menu-icon-svg{
        visibility: visible;
    }
}

.tooltip-icon {
    margin-left: 5px;
    width: 15px;
    height: 15px;
}

.info-icon-logo {
    margin-left: 5px;
}

.menu-icon-svg {
    visibility: hidden;
    position: absolute;
    right: 5px;
    padding: 0px 10px;
}

.plugin-menu-container-data {
    min-height: 30px !important;
    font-size: 12px !important;
    color: #16365F !important;
}

.rotated-icon {
    transition: transform 0.3s ease;
    transform: rotate(90deg);
}