$primary: #16365F;
$primary-button: #16365F;
$primary-light: rgba(22,54, 95, 0.1);
$primary-light-2: rgba(71, 1, 55, 0.03);
$secondary: #16365F;
$muted: #6b859e;
$info: #c7d0d9;
$text-2: #707070;
$disabled:#e4e6dc;
$light-text:#6B859E;

$avatar-background: #e0cc4d;
$paper-box-shadow: 3px 8px 15px rgba(0, 0, 0, 0.16);
$button-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
$table-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
$checkbox-color: #018786;

$link-color: #7b6be1;
$poppins: "Poppins";
$avatar-back-green: #b8f7c7;
$avatar-back-blue: #83ccfc;

$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

$white: #fff;
$block-background: #f9fbe8;

$button-primary: #ff5668;
$button-primary-hover: $primary;
$button-primary-text: $white;

$button-secondary: $info;
$button-secondary-hover: $muted;

$border-primary: $primary;
$border-secondary: $info;
$border-bottom-1px: 1px solid rgba(224, 224, 224, 1);

$switch-checked: #6200ee;
$link-color: #7b6be1;

$selected-row-color: rgba(25, 118, 210, 0.08);
$cancel-button: red;
$black-color: black;
$payment-status-color: #f8faf5;
$payment-text-color:#9abc66;

$popper-box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;

$box-shadow: 0px 1px 3px 1px #0000002b;