@import "./../../../styles/variables.scss";

.gsheetContainer {
    min-height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    color: #16365F;
    font-size: 13px !important;
}

.header-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #80808061;

    button {
        background-color: white !important;
        padding: 1px 1px 0px 0px !important;
        // border: 1px solid gray;
        margin-inline: 5px;
        min-width: 35px;
        font-size: 18px;
        font-weight: 600;
    }
}

.flex {
    display: flex;
    align-items: center;
    justify-self: start;
    gap: 8px;

    button {
        background-color: white !important;
        padding: 1px 4px !important;
        border: 1px solid gray;
        margin-left: 30px
    }
}

.source {
    display: flex;
    align-items: center;
    padding: 10px 5px;
    cursor: pointer;

    span {
        font-size: 8px;
        font-style: italic;
        margin-left: 5px;
    }
}

.source:hover {

    background: #00000017;
}

.source img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.group-image {
    height: 13px;
    width: 12px;
    margin-left: 4px;
}

.ml-10 {
    margin-left: 24px;
}
.mt-5 {
    margin-top: 5px;
}

.collection-params-container {
    padding: 0px 0px;
}

.run-btn {
    width: 100% !important;
    position: absolute !important;
    bottom: 0 !important;
}

.loading-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.plugin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #80808061;

    svg {
        height: 36px;
        // padding-left: 50px;
        // margin-bottom: 2.5px;
    }

    button {
        border: none;
        background: none;
        color: inherit;
        padding: 0px;
        margin: 0px;
    }
}

.text-field1 {
    margin: 5px 0 !important;
    background-color: white;

    input {
        height: 23px;
        padding: 8.5px 11px 4px;
        font-size: 12px !important;
        background-color: white;
    }
}

.parameter-div{
    display: flex;
    justify-content: left;
    align-items: center;
}

.parameter-checkbox-div{
    padding-left: 12px;
    padding-top: 3px;
    margin-right: -15px;
}

.param-tooltip{
    padding-top: 2px;
}

.filter-box {
    width: 330px;
    padding-left: 12px;
    padding-top: 3px;
    padding-right: 5px;

    .bold{
        font-weight: 700;
    }

    .italic {
        font-style: italic;
    }
}

.filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 4px;

    button {
        border: none;
        background: none;
        color: #4D53E0;
        padding: 0px;
        margin: 0px;
    }

    svg {
        font-size: 13px;
    }
}

.filter-value {
    border-left: 1px solid #80808061;
    padding-left: 9px;
    margin-left:7px;

    // div {
    //     display: flex;
    //     // justify-content: center;s
    //     gap: 2px;
    //     align-items: center;
    // }

   
}

.parameter-heading {
    display: flex;
    align-items: center;
    gap: 3px;

    svg {
        font-size: 12px;
        color: #16365F;
    }
}

.param-multiselect-chip{
    width: 100%;
    overflow-y: auto;
    cursor: pointer;
    max-height: 88px;
}
.default-value-dropdown{
    height: 50px;
}

.selected-dynamic-opt {   
    background-color: $selected-row-color !important;
    color: $primary !important;
}

.input-end-adornment {
    display: flex;
    align-items: center;
}

.browse-button {
    display: none;
}

.input-end-adornment:hover .browse-button {
    display: inline-flex;
}
.drive-folder-title-div{
    display: flex;
}
.drive-folder-title{
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    font-size: 21px;
}