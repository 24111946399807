/** @format */

@import "./../../styles/variables.scss";

.data-collections-container {
  margin-right: 20px;
}

.collections-header-div {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 15px;
}

.data-name-div {
  margin-block: auto;

  div {
    display: grid;
  }

  span {
    color: $secondary;
    font-size: 14px;

    span {
      font-weight: 700;
    }
  }

  label {
    color: $secondary;
    font-weight: $font-weight-bold;
    font-size: 20px;
    font-family: "Poppins";
  }
}

.filter-icons {
  font-size: 20px;
  color: $secondary;
}

.data-coll-icon {
  margin-right: 2px;
}

.temp {
  margin-right: 10px !important;
}

.add-new-btn-data a {
  display: flex;
  align-items: center;
}

.add-new-btn-data button {
  border-radius: 25px !important;
  box-shadow: $button-box-shadow;
  font-weight: 400 !important;
}

.search-btn-div {
  display: flex;
  align-items: center;
  // gap: 80px;
  // justify-content: space-between;
  // width: 45%;
}

.collections-text-field {
  margin: 1em 0 !important;

  label {
    padding-inline-start: 30px;
  }
}

.label-text-field {
  margin: 1em 0 !important;
}

.data-collections-table {
  display: flex;
  justify-content: space-between;
}

.groupname-div {
  label {
    display: block;
    padding: 10px 15px;
    font-size: 14px;
    color: $muted;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      font-weight: $font-weight-semibold;
      color: $secondary;
      background: $avatar-background;
    }
  }
}

.coll-sec-header {
  border-bottom: $border-bottom-1px;
  padding-right: 20px;
}

.col-dropdown {
  gap: 20px;
}

.collection-header {
  display: flex;
  align-items: center;
  padding: 16px;

  label {
    display: block;
    color: $secondary;
    font-size: 15px;
    font-weight: $font-weight-semibold;
  }
  span {
    color: $muted;
    font-size: 13px;
  }
  svg {
    margin-right: 20px;
  }
}

.collection-table {
  td {
    font-size: 15px;
    padding: 10px 16px 10px 16px;
  }
  th {
    font-size: 15px;
  }
}

.group-name-selected {
  margin: 10px 16px 10px 16px;
  display: block;
  font-size: 15px;
  color: $secondary !important;
  font-weight: $font-weight-bold !important;
  height: 40px !important;
  min-height: 40px !important;
}

.group-name {
  color: $secondary !important;
  font-weight: $font-weight-semibold;
  height: 40px !important;
  min-height: 40px !important;
}

.filter-div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-box;
  display: box;
  border-bottom: $border-bottom-1px;
  border-top: $border-bottom-1px;
  justify-content: center;
  padding: 8px 15px;
  width: auto;
  overflow-x: auto;
  hr {
    border-width: 1px;
  }

  label {
    font-size: 13px;
    color: $text-2;
    margin-left: 8px !important;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      color: $secondary;
      font-weight: $font-weight-semibold;
    }
  }
}

.collection-paper {
  // width: 70%;
}

.filter-paper {
  width: 30%;
  margin-right: 25px;
}

.filter-border {
  border-right: 1px solid $text-2;
  display: inline;
  margin: 0 15px;
  height: 20px;
}

.filter-flex-div {
  display: flex;
  align-items: center;

  img {
    width: 15px;
  }
}

.selected-menu {
  border-right: 5px solid $primary;
}

.search-span-svg {
  display: contents;
}

.collection-row {
  &:hover {
    cursor: pointer;
  }
}

.search-input {
  width: 30%;
}
.collection-error {
  border-bottom: $border-bottom-1px;
}

.flex-div-cell {
  display: flex !important;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.mb-13 {
  margin-bottom: 13px !important;
}

.collection-cards {
  display: flex;
  height: 65px;
  align-items: center;
  justify-content: start;
  overflow-y: hidden;
  overflow-x: scroll;
  gap: 5px;
}

.collection-paper-group {
  display: grid;
}

.collection-menu-item {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: 38px;
  padding-left: 12px;

  img {
    width: auto;
    height: 28px;
  }
}

.collection-filters {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}


.connection-search-div {
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    color: $secondary ;
  }
}

.tableHeight{
  height:calc(100vh - 340px);
}

.conn-inactive-span{
  font-style: italic;
  padding-left: 8px;
  font-size: 16px;
  font-family: 'Segoe UI';
  padding-bottom: 4px;

}