@import "../../styles//variables.scss";

.selected-tenant {
  background-color: $primary !important;
  color: white !important;
  font-size: 13px !important;
  border-bottom: 1px solid $primary !important;
}

.selected-tenant:hover {
  background-color: $primary !important;
}

.tenants-menu div{
  box-shadow: $paper-box-shadow !important;
}

.tenants-menu ul {
  padding-top: 0;
  padding-bottom: 0;
  border: 2px solid #707070;
}
  
.tenants-menu li {
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 13px;
  border-bottom: 1px solid #eee;
  color: #000000;
  text-decoration: none;
}

.tenants-menu li:hover {
  background-color: $avatar-background;
  color: $secondary;
  cursor: pointer;
}
