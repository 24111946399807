@import "./../../../styles/variables.scss";

.plan-type-container{
    margin-right: 40px;
}
.plan-type-container-update{
  margin-top: 15px;
  margin-right: 40px;
}
.root {
    width: 200px;
    max-width: 200px;
    border: 0.5px solid #6b859e;
    margin-top: 10px;
    border-radius: 5px;
    padding: 0px;
}
.plane-type {
    width: 200px;
    border-bottom: 0.5px solid $muted;
    padding: 0px;
    max-width: 200px;
    background-color: transparent;
    cursor: pointer;
}
 
  .plane-type-item {
    width:168px;
    display: flex;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    align-items: center;
    padding-top: 13px;
    padding-bottom: 13px;
    justify-content: flex-start;
    text-decoration: none;
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: normal !important;
    border-radius: 2px;
  }
  
  .plane-type-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .plane-type-item span {
    font-weight: normal !important;
  }
  .plane-type-item_selected {
    width: 168px;
    display: flex;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    align-items: center;
    padding-top: 13px;
    padding-bottom: 13px;
    justify-content: flex-start;
    text-decoration: none;
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: $info;
    border-radius: 2px;
  }
  
  .plane-type-item_selected span {
    font-weight: bold !important;
  }