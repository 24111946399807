@import "./../../styles/variables.scss";
.step-button {
  z-index: 1;
  color: $muted;
  margin-left: 4px;
  height: 25px;
  width: 25px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: $info;
  font-size: 0.75em;
  font-weight: bold;
  position: relative;
  align-self: baseline !important;
  label {
    position: absolute;
    top: 4px;
  }
}

.margin-3 {
  margin-left: 3px;
}
.activeOrCompleteState {
  color: $white;
  background-color: $primary;
}
.button-box {
  text-align: start;
  padding-right: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  button {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
  }
}

.switch-btn-text {
  color: $primary;
  left: 0px !important;
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.optionalLabelBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  margin-top: 2px;
  img {
    height: 25px;
    width: 25px;
  }
}

.completed-icon {
  left: 7px;
}

.step-label-div {
  display: grid;
  margin-bottom: 10px;

  label {
    color: $secondary;
    font-size: 14px;
  }

  span {
    color: $muted;
    font-size: 16px;
  }
}

.ml--24 {
  margin-left: -24px !important;
}

.ml--21 {
  margin-left: -21px !important;
}

.stepcolumn-end-margin {
  margin-left: 1px;
}

.step-label-container {
  margin: 8px 0px -18px -24px;
  border-left: 1px solid #bdbdbd;
  padding-left: 35px;
  width: fit-content;
  max-width: 50%;

  th {
    color: $secondary !important;
    font-size: 15px;
    white-space: nowrap;
    border-top: $border-bottom-1px;
  }

  td {
    color: $muted !important;
    font-size: 14px;
  }
}

.label-button-div {
  display: flex;
  justify-content: space-between;
  width: 51.3%;
}

.test-stepper-alignment {
  margin: 8px 0px -18px -24px !important;
  border-left: 1px solid #bdbdbd;
  padding-left: 35px;
}

.test-step-container {
  margin-top: 20px !important;
  max-height: 400px;
  overflow: auto;
  th {
    color: $secondary !important;
    font-size: 15px;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    border-top: $border-bottom-1px;
    //padding: 11px 0px !important;
  }

  td {
    color: $muted !important;
    font-size: 14px;
    padding: 9px 16px !important;
  }
}

.testData {
  width: 1250px !important;
}

.dataTransformationScript {
  width: 100% !important;
  color: #6b859e !important;
}
.dataTransformationScript textarea {
  width: 1226px !important;
  height: 200px !important;
}
.step-parameter-container {
  margin: 8px 0px -18px -24px;
  border-left: 1px solid #bdbdbd;
  padding-left: 35px;
  width: fit-content;

  th {
    color: $secondary !important;
    font-size: 15px;
    white-space: nowrap;
    border-top: $border-bottom-1px;
    padding: 11px 16px !important;
  }

  td {
    color: $muted !important;
    font-size: 14px;
    padding: 9px 16px !important;
  }
}

.collection-type-step-label {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 13.5px 14px;
  margin-top: 5px;
  // width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.coll-as-conn {
  display: flex;
  align-items: center;
  margin-left: -12px;

  label {
    color: $secondary;
    width: max-content;
  }

  &:hover {
    cursor: default !important;
  }
}

.save-changes-button-div {
  position: sticky;
  top: 84px;
  display: flex;
  justify-content: end;
  margin-top: -37px;
  z-index: 1300;
}
