@import "./../../styles/variables.scss";

.subGroup {
  margin-left: 25px;
  font-size: 16px;
}

.group-container {
  display: flex;
  margin-top: 19px;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 13px;
}
.subgroup-container {
  color: $secondary;
  padding-right: 10px;
  padding-left: 10px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  width: 40%;
  background-color: $white;

  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  border-bottom: 1px solid #70707054;
}
.subgroup-container:hover {
  background-color: #eeeeee;
}

.group-subgroup-lebel {
  color: $secondary;
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 16px;
  font-family: "Poppins";
}

.edit-user-icon {
  display: block !important;
}
.subgroup-collection{
  position: absolute;
  right: 96px;
}
.subgroup-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  align-items: center;
}

.subgroup-name {
  width: 50%;
}

.button-group {
  display: flex;
  column-gap: 5px;
  cursor: pointer;
}
.total-users-div{
  color: $secondary;
  font-size: 14px;
}
.total-users-div span{
  color: $secondary;
  font-size: 14px;
  font-weight: bold;
}
.button-group-first {
  right: 1px;
    position: absolute;
  display: flex;
  column-gap: 5px;
  cursor: pointer;
}
.subgroup-textBox {
  margin-right: 17%;
}
