@import "./../../../styles/variables.scss";

.user-form-container {
  margin: 0 auto;
  margin-block-start: 1%;
  //margin-block-end: 5%;
  height: -moz-fit-content;
  height: fit-content;
  //width: 40%;
  background: white;
  padding: 10px 8% 20px 8%;
  border-radius: 10px;
  //box-shadow: $paper-box-shadow;
}

.invite-buttons{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
}
.invite-cancel-button{
  color: #4D53E0 !important;
  margin-right: 20px !important;
}

.save-btn-div button {
  text-transform: capitalize;
  border-radius: 6px;
  padding: 8px 2%;
  min-width: 130px;
  font-weight: 600;
  margin-left: 2%; 
}

.user-link {
  all: unset;
  width: 100%;
  display: contents;
}

.generate-password-div {
  display: flex;
  align-items: center;

  label {
    color: $link-color !important;
    font-weight: 400 !important;
    font-size: 14px;
    text-transform: capitalize;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }

  svg {
    margin-left: 15px;
    color: $primary;

    &:hover {
      cursor: help;
    }
  }
}

.copy-btn-div button {
  text-transform: capitalize;
  border-radius: 20px;
  padding: 8px 5%;
  margin-left: 25px !important;
  min-width: 100px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
      rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  }
}

.copy-btn-div {
  padding: 0px 24px 25px 24px !important;
}

.mui-dialog {
  h2 {
    color: $secondary;
    padding: 16px 24px 0px 24px !important;
    // font-weight: $font-weight-semibold;
  }

  p {
    color: $secondary;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 5px 20px;
    white-space: nowrap;
    text-align: center;
    background: $avatar-background;
    color: $secondary;
    font-weight: $font-weight-semibold;
    font-size: 20px;
    letter-spacing: 8px;
  }
}

.mui-dialog [role="dialog"] {
  padding: 10px 20px !important;
}

.dialog-content{
  padding: 20px 24px 30px 24px !important;
}


.show-pass-icon {
  position: relative;
}

.show-pass-icon svg {
  color: rgba(0, 0, 0, 0.6) !important;
  position: absolute;
  top: 30px;
  right: 25px;
  &:hover {
    cursor: help;
  }
}