.group-table{
    display: grid;
    height: 450px;
}

.table-style{
    height:200px;
    overflow-y: auto;
}

.dialog-button{
    display: flex;
    justify-content: end;
    margin-right: 10px;
    margin-bottom: 10px;
}