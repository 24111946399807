@import "./../../../styles/variables.scss";

.column-basic-info-header {
    font-family: "Poppins";
    font-size: 16px;
    color: $primary;
    font-weight: 600;   
}
.display-format-list{
    margin-top: 15px !important;
}
.column-rename-cancel-button{
    color: #4D53E0 !important;
    font-size: 12px !important;
}
.buttons-flex{
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
    margin-top: 20px !important;
}
.buttons-flex button{
    margin-left: 25px;
}