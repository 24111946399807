@import "./../../styles/variables.scss";

.permission-container {
  margin-right: 20px;
}

.add-btn-div {
  display: flex;
  justify-content: space-between;
}

.users-name-div {
  display: grid;
  margin-bottom: 20px;

  span {
    color: $secondary;
    font-size: 14px;

    span {
      font-weight: 700;
    }
  }

  label {
    color: $secondary;
    font-weight: 700;
    font-size: 20px;
    font-family: "Poppins";
  }
}

// .add-new-btn-user {
// margin-right: 20px;
// }

.add-new-btn-user a {
  display: flex;
  align-items: center;
}

.add-new-btn-user button {
  border-radius: 25px !important;
  box-shadow: $button-box-shadow;
  font-weight: 400 !important;
}

.add-new-link {
  text-decoration: none;
  color: $white;
}

.plus-svg {
  font-size: 1.3em;
  font-style: normal;
  padding-right: 10px;
}

.report-label {
  display: grid;

  span {
    color: $secondary;
    font-weight: $font-weight-semibold;
    font-size: 15px;
  }

  label {
    color: $muted;
    font-size: 13px;
  }
}

.report-permission-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4em;
}

.report-svg {
  display: flex;
  justify-content: space-between;

  svg {
    margin-inline-end: 15px;
    margin-top: 6px;
  }
}

.user-role-row{
  display: contents;
}

.user-role-paper {
  margin-bottom: 20px;
  box-shadow: $table-box-shadow !important;

  li {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.report-table-paper {
  box-shadow: $table-box-shadow !important;
  padding-block-end: 5%;

  th {
    color: $secondary;
    font-weight: $font-weight-semibold;
    font-size: 15px;
    padding: 6px 10px;
  }
  td {
    color: $muted;
    font-size: 15px;
    padding: 1px 5px 1px 10px;
  }
  li {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.role-text-field, .connection-text-field {
  margin: 1em 0 !important;
  min-width: 300px !important;
}

.connection-text-field {
  margin-left: 3rem !important;
}

.name-cell {
  padding: 1px 5px 1px 0px !important;
}

.datasource-type-span {
  display: flex;
  align-items: center;

  img {
    height: 30px;
    width: 37px;
    margin-inline-end: 10px;
  }
}

.all-connection {
  margin-left: 10px;
  font-size: 15px;
}

.selected-user-role {
  background-color: $primary !important;
  color: white !important;
  &:hover {
    background-color: $primary;
  }
}

.menu-role-div, .menu-connection-div {
  ul {
    li {
      font-size: 13px !important;
      &:hover {
        background-color: $avatar-background;
        color: $secondary;
      }
    }
  }
}

.search-collection-input {
  width: 35%;
}