@import "./../../styles/variables.scss";

.button {
  &-rounded {
    border-radius: 20px !important;
  }
  &-primary {
    background-color: $button-primary !important;
    color: $button-primary-text !important;
    &:hover {
      background-color: $button-primary-hover !important;
    }
  }
  &-secondary {
    background-color: $button-secondary !important;
    color: $secondary !important;
    &:hover {
      background-color: $button-secondary-hover !important;
    }
  }
  &-basic {
    background-color: $white !important;
    color: $switch-checked !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    &:hover {
      background-color: $block-background !important;
    }
  }
  padding: 2% 10% !important;
}

.contained-primary-unrounded-button {
  background-color: $button-primary !important;
  box-shadow: 0 3px 4px rgba(0, 0, 0, .3);
  color: $white !important;
  padding: 6px 16px !important;
}
