@import "./../../../styles/variables.scss";

.checkoutDiv {
  margin-left: 10px;
  display: block;
}

.checkoutDiv div {
  margin-right: 5px;
  color: $secondary !important;
  font-size: 16px;
}

.sub-heading {
  color: $secondary !important;
  font-size: 16px;
  font-weight: normal;
}

.heading {
  margin-top: 30px;
  font-size: 22px !important;
  font-weight: 700 !important;
}

.checkoutDiv span {
  font-size: 18px;
  font-weight: bold;
}

.subscription-plan {
  margin-top: 20px;
  display: flex;
}

.subscription-plan text {
  font-size: 16px;
  font-weight: bold;
  color: $secondary !important;
}
.alphasheet-text{
  font-size: 25px !important;
}