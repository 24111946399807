@import "./../../../styles/variables.scss";

.test-step-container {
    margin-top: 0px !important;
    max-height: calc(100vh - 280px);
    overflow: auto;
    th {
      color: $secondary !important;
      font-size: 15px;
      font-weight: $font-weight-semibold;
      white-space: nowrap;
      border-top: $border-bottom-1px;
      //padding: 11px 0px !important;
    }
  
    td {
      color: $muted !important;
      font-size: 14px;
      padding: 9px 16px !important;
    }
  }

  .checkbox-title{
    color: $secondary !important;
    font-size: 15px;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    padding-top: 1px;
  }