.main-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80vh;
    padding: 8px;
    font-size: 13px !important;
}
.connect-button-text{    
    font-size: 13px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
}
.welcome-text{
    font-size: 14px !important;
    margin-bottom: 10px;
    display: flex;
}
.info-text{
    margin-bottom: 15px;
}
.info-text span{
    color: #4D53E0;
    cursor: pointer;
    margin: '0px 5px';
}
.asheet-svg{
    height: 21px;
    margin-top: -5px;
    width: 70px;
}
.connect-data-source button{
    width: 250px;
    box-shadow: 0px 2px 5px grey;
    padding: 3px;
    border-radius: 10px;
    margin-top: 5px;
}