.group-label-div{
    font-weight: bold;
    color: #16365F !important;
}

.subgroup-label-div{
    color: #16365F !important;
    margin-left: 20px;
    margin-top: 10px;
}
.make-collection-div{
    display: flex;
    justify-content: left;
    align-items: center;
}