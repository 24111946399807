@import "./../../../styles/variables.scss";

.description-box {
    margin-left: 50px;
    margin-right: 20px;
}
.plan-heading {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $muted;
  width: 670px;
}
.plan-type {
  width: 79.5%;
  font-size: 16px !important;
  font-weight: bold !important;
  display: block;
  color: $secondary !important;
}
.amount {
  width: 14%;
  font-size: 16px !important;
  font-weight: bold !important;
  color: $secondary !important;
  display: block;
}
.units {
  width: 24.3%;
  font-size: 16px !important;
  font-weight: bold !important;
  display: block;
  color: $secondary !important;
}
.plan-type_1 {
    width: 350px !important;
}
.free{
  display: flex;
  padding-top: 25px;
  padding-left: 20px;
  padding-bottom: 24px;
}
.free-value{
  padding-right: 10px;
  font-weight: bold !important;
  font-size: 16px;
}
.plan-discription-text {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  width: 670px;
}
.small-business {
  font-size: 16px !important;
  font-weight: bold !important;
  margin-bottom: 5px;
  color: $secondary !important;
}
.normal-text {
  margin-left: 7px;
  font-size: 17px !important;
  font-weight: normal !important;
  width: 100%;
}
.extra-space {
  margin-top: 100px;
}
.cancelButton {
  background-color: $cancel-button !important;
  margin-top: 10px !important;
  color: $white !important;
  margin-left: 5px !important;
}
.setUpButton {
  background-color: $info !important;
  margin-top: 10px !important;
  color: $secondary !important;
}
.units-value {
  height: 23px;
  width: 15%;
  margin-left: 50px;
  font-size: 16px !important;
  font-weight: bold !important;
  display: block;
  margin-top: -25px;
}
.units-value-update {
  height: 23px;
  width: 15%;
  margin-left: 70px;
  font-size: 16px !important;
  font-weight: bold !important;
  display: block;
  margin-top: -25px;
}
.small-value {
  color: $secondary !important;
  margin-top: 70px;
  font-size: 16px !important;
  font-weight: normal !important;
}
.small-value-usd {
  font-style: normal;
  font-weight: 500 !important;
  margin-top: 100px;
  font-size: 16px !important;
}
.small-value-usd span {
  margin-top: 1px;
  font-size: 16px !important;
  font-weight: bold !important;
}
.amount-value {
  width: 15%;
  font-size: 15px !important;
  font-weight: 500 !important;
  display: block;
  margin-left: 72.4px;
  color: $secondary !important;
}
.amount-value span {
  font-weight: bold !important;
  font-size: 15px !important;
  color: $secondary !important;
}
.amount-value-per-year {
  font-size: 10px !important;
  width: 66px;
  font-style: italic;
}
.totalSummary-view {
  display: flex !important;
  margin-top: 5px;
  align-items: center;
  justify-content: flex-end;
}
.total-view {
  padding-top: 5px;
  height: 42px;
  border-top: 1px solid $muted !important;
  border-bottom: 1px solid $muted !important;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.total-view div {
  font-size: 16px;
  padding: 5px;
}
.total-text {
  color: $secondary !important;
  font-weight: bold !important;
}
.total-text-value {
  color: $secondary !important;
  font-weight: bold !important;
}
.font-value {
  font-size: 20px !important;
}
.font-value span {
  font-size: 20px !important;
}
.standard-primary-unrounded-button {
  background-color: $button-primary !important;
  margin-top: 10px !important;
  width: 240px;
}
.totalSummary-view-update{
  //padding-right: 20px;
  display: flex !important;
  margin-top: 80px;
  justify-content: flex-end;
  align-items: center;
}
.sub-amount-value{
  font-size: 13px !important;
  font-weight: 500 !important;
  color: $primary-button !important;
  justify-content: space-between;
  display: flex;
}
.credit-card-info{
  display: flex;
  justify-content: center;
}
.credit-card-info label{
  color: #707070 !important;
}
.extra-info span{
  font-size: 15px;
  font-weight: 700;
}
.extra-info label{
  font-size: 15px;
  color: #707070 !important;
}
.extra-info{
  text-align: end !important;
  margin-top: 20px;
}