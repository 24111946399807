@import "./../../../styles/variables.scss";

.card-container {
  display: flex;
  gap: 36px 46px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 36px;
}

.total-div {
  color: $secondary;
}

.plus-icon {
  font-size: 1.3em;
  font-style: normal;
  padding-right: 10px;
}

.add-new-btn button {
  border-radius: 25px !important;
  box-shadow: $button-box-shadow;
  font-weight: 400 !important;
}

.add-new-btn a {
  display: flex;
  align-items: center;
}

.new-link {
  text-decoration: none;
  color: $white;
}

.card-link {
  color: $primary;
}

.add-new-btn {
  margin-right: 20px;
}

.data-source-card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px !important;
}

.datasource-name {
  width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
