.border-right{
    border-right: 1px solid #dde2eb !important;
}

.ag-theme-alpine {
    --ag-header-background-color: #16365F;
    --ag-header-color: rgb(228, 237, 250);
}

.ag-theme-alpine .ag-header-cell-text {
    color: white;
}

.ag-right-aligned-cell {
    text-align: right !important;
    border-right: 1px solid #dde2eb !important;
}
.grid-edit-icon{
    cursor: pointer;
    font-size: 15px !important;
    color: white;
    position: absolute;
    right: 10px;
}
.grid-display-name{
    margin-right: 20px !important;
    color: white;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.grid-column-header{
    display: flex;
    align-items: center;
    justify-content: end;  
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;  
}