@import "./../../../styles/variables.scss";

.collection-type-container {
  // display: grid;
  // width: 80%;
}

.collection-type-label {
  border-radius: 4px;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  display: block;
  min-width: 0;
  width: -webkit-fill-available;
  padding: 16.5px 14px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
}

.object-text-field {
  margin: 1em 0 !important;
  min-width: 300px !important;
  border-color: $primary !important;
  // input:focus {
  //   border-color: $primary !important;
  // }
}
.collection_label{
  display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5%;
}
.collection_label_div{
  display: flex;
}
.collection_label_div_sec{
  width: 100%;
}
