@import "../../../styles/variables.scss";

.upgrade-btn-data a{
    display: flex;
    align-items: center;
    color: rgba(221,75,78,1) !important; 
    text-transform: none;
    text-decoration: none;
  }
  
  .upgrade-btn-data button{
    border-radius: 5px !important;
    box-shadow: $button-box-shadow;
    font-weight: 400 !important;
    background-color: rgba(243,166,173,1) !important;
    margin-left: 55px;
    transition: background-color 0.2 ease;
  }
  
  .upgrade-btn-data button:hover{
    background-color: rgba(210,23,23,1)!important;
  }
  
  .upgrade-btn-data button:hover a{
    color: rgba(255,255,255,1)!important;
  }
  