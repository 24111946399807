@import "./../../../styles/variables.scss";

.user-defined-box {
  background-color: rgb(255, 255, 255);
  padding: 15px 10px 20px 30px;
  max-height: 550px;
  min-height: 475px;
  overflow: auto !important;
}

.value-text{
  margin-left:15px;
}

.default-text{
  margin-right: 35px;
}

.input-field-padding {
  column-gap: 10px !important;
  align-items: center;
  display: flex;
}

.button-flex {
  margin-right: 20px;
  column-gap: 30px;
  display: flex;
  justify-content: flex-end;
  margin-top: 4%;
}

.confirm-btn {
  position: absolute;
  margin-top: 30px;
  right: 20px;
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.add-list-btn {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  cursor: pointer;
  right: 101px;
  color: $primary-button;
  text-align: end;
  margin-right: 15px;
  margin-top: 5px;
  height: 24px;
}
.input-width {
  width: 448px;
}
.minus-border-icon {
  cursor: pointer;
}

.minus-icon{
  font-size: 2.5em;
  font-style: normal;
}

.input-field-box-padding {
  position: relative;
}
.column-name{
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 0px 25px;
  font-weight: 700;
}
.scroll {
  overflow-y: scroll;
  overflow-x: none;
  max-height: 350px;
}
.scroll-content{
  display: flex;
  justify-content: space-around;
  height: 70px;
}
.index{
  margin-top: 20px;
}
.btn-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 15px;
}
.btn-div button {
  text-transform: capitalize;
  padding: 5px 4%;
  min-width: 100px;
  font-weight: 600;
  margin-right: 5%;
}
.user-header {
  font-family: "Poppins";
  font-size: 27px;
  color: $primary;
  font-weight: 700;
  margin-left: 5px;
}
.user-defined-heading{
  display: flex;
  justify-content: space-between;
}
.text-field.no-spinner input[type="number"]::-webkit-inner-spin-button,
.text-field.no-spinner input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-field.no-spinner input[type="number"] {
  -moz-appearance: textfield;
}

.user-define-input{
  margin-bottom: 0px !important;
}