/** @format */

@import "./../../../styles/variables.scss";

.test-data-paper {
  // width: 200%;
  padding: 20px;
  p {
    color: $secondary;
  }
  padding-bottom: 0px;
}
.test-data-container{
  display: flex;
  justify-content: space-between;
}
.test-data-header{

}
.test-data-table{
  margin: 20px 0;
}
.test-data-message-div{
  display: flex;
  justify-content: end;
}
.test-button-div-width{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  text-align: right;
}
.test-button-div{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.test-step-container {
  margin-top: 20px;
  max-height: 473px;
  overflow: auto;
  th {
    color: $secondary !important;
    font-size: 15px;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    border-top: $border-bottom-1px;
    padding: 11px 16px !important;
  }

  td {
    color: $muted !important;
    font-size: 14px;
    padding: 9px 16px !important;
  }
}

.test-btn {
  text-align: end;
  display: flex;
  justify-content: end;
  column-gap: 20px;    
  margin-top: 10px;
}

.test-connection-div{
  display: flex;
  justify-content: end;
  column-gap: 20px;
}
.data-transform-title{
  margin-bottom: 20px !important;
}
.data-nav{
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  margin: 25px 0px;
}
.data-nav-item {
  border: 1px solid $info;
  border-left-width: 0;
  min-height: 48px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  color: $text-2;
  p > label {
    cursor: pointer;
  }
  &:first-of-type {
    border-left-width: 1px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-of-type {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.selected-nav{
  color: #16365F;
  background-color: rgba(71, 1, 55, 0.1);
}