/** @format */

.date-filter-fields-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  // padding: 10px;
  padding-top: 10px;
  // margin-top: 25px;
}

.date-condition-div {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.date-add-condition-value-div {
//   display: grid;
  width: 36%;
}

.date-add-next-condition-value-div {
  width: 48%;
}

.exact-date-add-condition-value{
    width: 100%;
    display: flex;
    gap:10px
    // padding-bottom: 10px;
}

.date-separator{
  display: flex;
  justify-content: center;
  align-items: center;
}