@import "./../../styles/variables.scss";

.link {
  all: unset;
  display: inherit;
  padding: 5px 10px;
  width: 100%;
  color: $secondary;
}

.link-closed {
  all: unset;
  display: table;
  width: 100%;
  color: $secondary;
}

.menu-svg {
  display: contents;
}

// .hue-sheet-svg span {
//   font-family: "Poppins";
//   //margin: 0 0 0px 5px;
//   font-size: 20px;
//   color: #16365F;
//   font-weight: $font-weight-bold;
//   margin-left: -6px;
// }

.a-sheet-complete-svg{
  position: absolute;
  left: 16px;
 :hover {
  cursor: pointer;
 }
}

.a-sheet-svg {
  position: absolute;
  left: 18px;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
}

.icon-DB:before {
  content: "\e900";
  color: $secondary;
}

.is-active {
  background-color: $primary;
  color: $white;
  border-radius: 4px;
}

.is-active-closed {
  background-color: $primary;
  color: $white;
  border-radius: 4px;
}

.menu-image {
  height: 22px;
  width: 22px;
}

.nav-link-item {
  height: 49px;

  span {
    font-size: 16px !important;
    font-weight: $font-weight-semibold;
  }
}

.new-menu-image {
  height: 26px;
  width: 26px;
}