@import "./../../../styles/variables.scss";
.container-change-password {
  margin: 0 auto;
  height: -moz-fit-content;
  height: fit-content;
  width: 88%;
  background: $white;
  padding: 5px 1% 10px 1%;
  border-radius: 10px;
  min-height: 300px;
  box-shadow: none !important;
}
.header {
  font-family: "Poppins";
  font-size: 27px;
  color: $primary;
  font-weight: 600;
  margin-left: 0px !important;
  margin-top: 15px !important;
}
.login-button {
  text-align: center;
  margin-top: 3%;
  padding-bottom: 10px;

  .link {
    color: $button-primary-text !important;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: capitalize;
    text-decoration: none;
    padding: 0%;
  }
}

.login-button button {
  text-transform: capitalize;
  border-radius: 25px !important;
  padding: 2% 10%;
  font-weight: 600;
  background-color: $button-primary !important;
  &:hover {
    background-color: $button-primary-hover !important;
  }
}
.error-msg {
  margin-bottom: 10px;
  font-weight: $font-weight-semibold;
  width: fit-content;
  text-align: center;
  height: fit-content;
  background-color: $block-background;
  height: 30;
  padding: 1px 10px 1px 10px;
}
.error-msg p {
  margin-top: 8px;
  color: $secondary;
  font-size: 14px;
}

.change-password-button{
  display: flex;
  justify-content: center;
  margin: 10px;
}

.change-password-button button{
  background-color: $button-primary;  
  width: 50%;
  border-radius: 5px;
  &:hover {
    background-color: $button-primary-hover !important;
  }
}