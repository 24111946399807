@import " ./../../../../../../src/styles/variables.scss";

.rest-nav-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  .rest-nav-item {
    width: calc(100% / 1);
    border: 1px solid $info;
    border-left-width: 0;
    min-height: 48px;
    margin: auto;
    padding-left: 10px;
    color: $text-2;
    p > label {
      cursor: pointer;
    }
    &:first-of-type {
      border-left-width: 1px;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
    &:last-of-type {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
  input[type="radio"]:checked + .rest-nav-item {
    color: $primary;
    background-color: $primary-light;
  }
  &.template-connection {
    width: 22% !important;
}
}
.rest-api-container {
  width: calc((100% / 7) * 3);
}
.parameters-container {
  color: $text-2;
  // padding: 0 10px;
  .row-wrapper {
    &.header {
      font-weight: $font-weight-semibold;
    }
    .w-20 {
      .items {
        border: 1px solid $info;
        border-left-style: none;
        padding: 8px 10px;
      }
      .items-padding {
        padding: 5%;
      }
      &:last-of-type {
        .items {
          border-right: none;
        }
      }
    }
    .form-wrapper {
      padding: 0;
    }
    &.body {
      .w-20 {
        .items {
          border-top: none;
        }
      }
    }
  }
  // .row-wrapper.body:nth-child(odd) {
  //   background-color: $primary-light-2;
  // }
}

.parameter-table {
  th {
    font-weight: $font-weight-semibold;
    color: $text-2;
  }
  td {
    color: $text-2;
  }
}
.parameter-cancel-btn {
  button {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
    padding: 6px 16px;
  }
}

.parameter-ok-btn {
  button {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.parameter-input-div {
  padding: 15px 0px 0px 0px;
}

.parameter-value-div {
  padding: 0px 0px 15px 0px;
}

.parameter-input-pad {
  padding-right: 10%;
}

.w-85 {
  width: 85%;
}

.form-row {
  display: block !important;
}

.cookie-auth-container {
  padding-top: 20px;
}

.headless-silent-checkbox {
  color: $text-2;
}

.auth-request-div{
  margin-top: 20px;
  margin-bottom: 20px;
}

.token-request-div{
  margin-top: 20px;
  margin-bottom: 20px;
}

.refresh-request-div{
  margin-top: 20px;
  margin-bottom: 20px;
}

.public-tally-checkbox{
  color: $secondary;
  margin-left: -8px !important;
}
.tally-link-div{
  display: flex;
  margin-top: 10px;
  column-gap: 10px;
  span{
    font-size: 17px;
  }
}
.tally-link{
  color: #1922F2;
  font-size: 14px;
  cursor: pointer;
}
.line-btw-tally-link{
  border: 0.5px solid #6b859e8c;
  height: 22px;
}