.source {
    display: flex;
    align-items: start !important;
    padding: 10px 10px;
    cursor: pointer;
  
    span {
      font-size: 10px;
      font-style: italic;
      margin-left: 5px;
    }
  }
  
.source:hover {
    background: #00000017;
}

.source img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.info-icon {
    display: none;
    width: 16px !important;
    position: absolute;
    right: 10px;
}

.source:hover .info-icon {
    display: inline-block;
}

.gsheetContainer {
    min-height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    color: #16365F;
    font-size: 13px !important;
}

.tabs {
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
}

.tab {
    padding: 5px 5px;
    cursor: pointer;
}
  
.tab.active {
    font-weight: bold;
    background-color: #f9fbe8;
    border: 1px dashed gray;
}

.connect-new-btn {
    margin-right: 5px;
    color: #4D53E0;
    cursor: pointer;
}

// 
.content div.active {
    display: grid;
    gap: 0px !important;
}

.circularLoader {
    margin: auto;
    display: grid;
    align-items: center;
    margin-block: 81%;
    margin-inline: 50%;
}

.plugin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #80808061;
  
    svg {
      height: 36px;
      // padding-left: 50px;
      // margin-bottom: 2.5px;
    }
  
    button {
      border: none;
      background: none;
      color: inherit;
      padding: 0px;
      margin: 0px;
    }
}

.indented {
    margin-left: 20px;
}

.subgroup {
    font-size: 0.9em;
    margin-top: 0.5rem;
}
  