@import "../../../src/styles/variables.scss";

.future-dialog-popup{
  padding: 10px 10px;
}
.future-connector-title-div{
  display: flex;
  justify-content: flex-start;

  label{
    font-size: 23px;
    margin-left: 20px;
    color: $primary-button;
    margin-top: 0px;
  }
}

.future-connector-sub-title-div{
  display: flex;

  label{
    font-size: 20px;
    color: $primary-button;
    font-weight: 600;
  }
  img{
    margin-left: 20px;
    height: 21px;
    margin-top: 1px;

  }
}

.future-connector-content-div{
  margin-top: 23px;
  margin-left: 10px;

  p{
    color: $light-text;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 40px;
  }
}

.connector-not-found-title-div{
  display: flex;
  justify-content: flex-start;
  column-gap: 21px;
  color: $primary-button;
  font-size: 20px;

  .connector-not-found-header{
    
  }

  .connector-not-found-dark-sub-header{
    font-weight: 600;
  }

  .connector-not-found-sub-header{
    font-size: 15px;
    color: $light-text;
  }
}

.success-message-div{
  text-align: center;
  h2{
    margin-bottom: 0;
  }
  img{
    height: 85px;
  }
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.checkbox-documentation-avialable{
  color: $secondary;
  margin: 10px 10px;

  svg{
    font-size: 28px !important;
  }
  span{
    font-size: 17px;
    color: rgba(0, 0, 0, 0.6);
  }
}