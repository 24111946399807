@import "./styles/variables.scss";

body::-webkit-scrollbar {
  width: 10px; 
  height: 10px;
}
body::-webkit-scrollbar-track {
  background: $avatar-background;
}
body::-webkit-scrollbar-thumb {
  background-color: $primary; 
  border-radius: 20px; 
}
body::-webkit-scrollbar-track-piece {
  background-color: $avatar-background;
}
body::-webkit-scrollbar-track-piece:end {
  margin-right: 50px; 
}
body::-webkit-scrollbar-track-piece:start {
  margin-left: 50px;
}

body {
  *{
    scrollbar-width: 10px; 
    scrollbar-color: $primary;
  }
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  *::-webkit-scrollbar-track {
    background: $avatar-background; 
  }
  *::-webkit-scrollbar-thumb {
    background-color: $primary; 
    border-radius: 10px; 
  }
  *::-webkit-scrollbar-track-piece {
    background-color: $avatar-background;
  }
}

.body::-webkit-scrollbar-track-piece:end {
  margin-right: 50px; 
}

.body::-webkit-scrollbar-track-piece:start {
  margin-left: 50px;
}

.app-container {
  min-height: 100vh;
  width: 100vw;
  background-color: $block-background;
}

.as-spinner {
  width: -webkit-fill-available;
  height: 5px;
  // background: linear-gradient(90deg, #0001 55%, #16365F 65%, #0001 70%) #E0CC4D;
  background: linear-gradient(90deg, #0001 60%, #16365F 65%, #0001 70%) #e0cc4d;
  background-size: 300% 100%;
  animation: pos 1s infinite linear;
  position: absolute;
  top: 0;
}

@keyframes pos {
  0% {
    background-position: right;
  }
}