.confirmation-dialog {
    overflow-x: auto;
    background-color: white;
    display: grid;
    height: 130px;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.confirmation-dialog-overlay {
    background: rgba(150, 150, 150, 0.5);
    height: 100%;
    width: 100%;
    padding-top: 200px;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.confirmation-button-div button{
    text-transform: none;
    font-weight: 600;
    width: 100px;
}

.confirmation-button-div{
    display: flex;
    justify-content: end;
}

.confirmation-text {
    font-size: 14px;
}

.confirmation-text span {
    font-weight: 700;
}