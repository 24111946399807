@import "../../styles//variables.scss";

.header-avatar {
  color: $secondary;
  font-weight: bold;
  position: inherit;
  top: -1px;
}

.border-btm {
  border-bottom: 0px solid #eee !important;
}

.avatar:hover {
  cursor: pointer;
}

.avatar {
  border: 2px solid #707070;
  overflow: auto !important;
  display: inline-table !important;
}

.disable-hover:hover {
  cursor: default !important;
}
.username-menu-container {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.username-menu-container:hover {
  cursor: pointer;
}

.dropdown {
  right: 44%;
  position: absolute;
  width: max-content;
  background: #fff;
  transform: translateX(50%);
  border-radius: 4px;
  border: 2px solid #707070;
  z-index: 1;
  margin-top: -3px;
  display: none;
  box-shadow: $paper-box-shadow;
}

.username-menu-container:hover .dropdown {
  display: block !important;
}

.menu-arrow {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-menu {
  margin: 0;
  padding: 0;
  overflow: auto !important;
  text-align: start;
}

.dropdown-menu li {
  list-style: none;
}

.dropdown-menu li span {
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 13px;
  border-bottom: 1px solid #eee;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.dropdown-menu li span:hover {
  background-color: $avatar-background;
  color: $secondary;
  cursor: pointer;
}
