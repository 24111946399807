.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); // Add semi-transparent overlay
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
}

.popup-container {
    position: relative; // Needed for absolute positioning of children
    background: #fff;
    border-radius: 8px;
    padding: 25px;
    width: 500px;
    max-height: 80vh;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.popup-close-container {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
}

.close-popup-icon {
    cursor: pointer;
    padding: 5px;
    background: #f5f5f5;
    border-radius: 50%;
    transition: all 0.3s ease;
    
    &:hover {
        background: #eee;
    }

    .close-icon {
        width: 20px;
        height: 20px;
        display: block;
    }
}

.popup-message-list {
    height: 150px;
    overflow-y: auto;
    padding-right: 15px; // Add space for scrollbar
    margin-top: 10px;

    p {
        font-size: 16px;
        color: #333;
        margin: 8px 0;
    }
}

.popup-footer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
}