@import "./../../styles/variables.scss";

.users-container {
  margin-right: 20px;
}

.user-list-paper {
  border-radius: 4px !important;
  box-shadow: $table-box-shadow !important;
}

.user-list-paper ul {
  padding-top: 0px;
  padding-bottom: 0px;
}
.owner-tag {
  margin-left: 5px;
  margin-bottom: 0.35em;
  color: #16365F;
  margin-bottom: 0px;
  background-color: #dce6f38f;
  font-size: 11px;
  font-weight: 500;
  padding: 2px 7px 1px 7px;
  border-radius: 5px;
}
.user-avatar-green {
  color: $secondary !important;
  border: 2px solid $secondary;
}

.user-avatar-primary {
  color: white !important;  
}

.user-avatar-super {
  color: $secondary !important;  
}

.user-avatar-blue {
  color: rgba(0, 0, 0, 0.6) !important;  
}

.user-avatar span {
  position: absolute;
  left: 0;
  right: 0;
  top: 3px;
  bottom: 0;
  font-size: 17px;
}

.list-item {
  background: #eeeeee;
}

.total-users {
  margin-top: 20px;
  display: grid;
  margin-bottom: 20px;

  span {
    color: $secondary;
    font-size: 14px;

    span {
      font-weight: 700;
    }
  }
  .menu-show-div {
    z-index: 100;
    position: absolute;
  }
  label {
    color: $secondary;
    font-weight: bold;
    font-size: 20px;
    font-family: "Poppins";
  }
}
.total-invited-users {
  display: grid;
  margin-bottom: 20px;

  span {
    color: $secondary;
    font-size: 14px;

    span {
      font-weight: 700;
    }
  }

  label {
    color: $secondary;
    font-weight: 700;
    font-size: 20px;
    font-family: "Poppins";
  }
}
.username-grid {
  width: 30%;
}

.report-grid {
  width: 52%;

  span {
    color: $muted !important;
  }
}

.new-link {
  text-decoration: none;
  color: $white;
}

.plus-icon {
  font-size: 1.3em;
  font-style: normal;
  padding-right: 10px;
}

.add-btn {
  display: flex;
  justify-content: space-between;
}

.avatar-cell {
  padding: 8px 0px 2px 20px !important;
}

.parent {
  overflow: hidden;
  position: relative;
}

.user-table-row {
  &:hover {
    cursor: pointer;
  }
}

.add-new-link a {
  display: flex;
  align-items: center;
}

.add-new-link button {
  border-radius: 25px !important;
  box-shadow: $button-box-shadow;
  font-weight: 400 !important;
}

.edit-user-icon {
  display: block !important;
}

.edit-user-menu {
  ul {
    padding: 0;
    border: 2px solid $text-2;
  }

  li:hover {
    background-color: $avatar-background;
    color: $secondary;
  }
}
