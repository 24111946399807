@import "./../../styles/variables.scss";

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 70px 0px;
    background: $block-background;
    height: 85vh;
}

.container h1 {
    color: $payment-text-color;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}

.container p {
    color: #404f5e;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    margin: 0;
}

.container i {
    color: $payment-text-color;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.card {
    height: 240px;
    margin: auto;
    background: $white;
    text-align: center;
    position: relative;
    padding: 15px 30px;
    font-weight: bold;
    box-shadow: 0px 1px 3px 1px #0000002b;
    border-radius: 5px;
    width: 660px;
}
.button-div{
    text-align: left !important;
    margin-left: 62px !important;
}
.order-header {
    margin-top: 50px !important;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.asheet-icon{
    margin-left: 5%;
    padding-top: 25px;
}
.order-header_info {
    text-align: left;
    margin-left: 13px;
}

.order-header_info div:first-child {
    color: #000000;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 5px;
}

.order-header_info div {
    color: #707070;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 2px;
}

.order-header_info div span {
    font-weight: bolder;
}
.continue-button{
    background-color: $button-primary !important;
}
.payment-status-div {
    border-radius: 200px;
    height: 200px;
    width: 200px;
    background: $payment-status-color;
    margin: 0 auto;
}

.shop_button {
    background-color: $button-primary !important;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid grey;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 20px;
    color: $white;
}

.continue_link{
    cursor: pointer;
}

.closeIcon {
    color: $cancel-button !important;
    margin-top: 10px;
    font-size: 18px;
    font-weight: normal;
}