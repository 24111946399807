.error {
    border: 1px solid red;
}
.text-field {
    margin: 1em 0 !important;
    width: 100%;
}

.menu-item-icon {
    margin: 0 12px 0 0 ;
    img {
        width: 28px;
        height: 28px;
    }
}
