.disconnect-button{
    text-transform: none;
}
.collection-button-div button{
    width: 200px;
    box-shadow: 0px 2px 5px grey;
    text-transform: none;
    border-radius: 10px;
}
.collection-button-div{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.success-view-div label{
    padding: 3px;
    font-size: 12px;
}
.success-view-div {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
}