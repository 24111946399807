.add-filter-modal{
    position: absolute;
    background-color: #F7F8FB;
    z-index: 10;
    box-shadow: 0 0 10px rgba(1, 1, 1, 0.5);
    border-radius: 10px;
    top: 30px;
    width: 620px;
    padding: 8px 10px;

    label{
        color: #16365F !important;
    }

    input{
        padding: 2.5px 6px !important;
        width: 100%;
    }
}

.add-new-modal{
    position: absolute;
    background-color: #ffffff;
    z-index: 10;
    box-shadow: 0 0 10px rgba(1, 1, 1, 0.5);
    border-radius: 10px;
    top: 30px;
    width: 385px;
    padding: 12px 14px;
    right: 0%;

    label{
        color: #16365F !important;
    }

    input{
        padding: 2.5px 6px !important;
        width: 100%;
    }
}

.filter-paper-div{
    background-color: #FFFFFF;
    border: 0.5px solid #C7D0D9;
    border-radius: 8px;
}

.add-condition-div{
    font-size: 13px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #16365F;
}

.add-condition-container{
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.add-condition-inpopup-container{
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.add-condition-highlight {
    color: #16365F;
    font-weight: 600;
    display: contents;
}

.cancel-link-button {
    background: none;
    border: none;
    color: #4D53E0; 
    cursor: pointer;
    font-size: 15px;
    padding: 7px;
}

.margin-bottom-18{
    margin-bottom: 18px;
}

.add-button-div{
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.add-condition-popup-container{
    position: absolute;
    background-color: #FFFFFF;
    z-index: 10;
    box-shadow: 0 0 10px rgba(1, 1, 1, 0.5);
    border-radius: 10px;
    top: 0;
    width: 300px;
    right: 346px;
    padding: 5px 10px;

    label{
        color: #16365F !important;
    }

    input{
        padding: 2.5px 6px !important;
        width: 100%;
    }
}

.fields-group{
    display: flex;
    // align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}

.fields-group-label{
    position: relative;
    display: flex;
    // align-items: center;
    gap: 50px;
    padding: 10px;
    word-wrap: break-word;
}

.delete-icon-div{
    position: absolute;
    right: 24px;
    top: 10px;

    svg{
        font-size: 24px !important;
        margin-top: 1px !important;
        color: #16365F !important;
        cursor: pointer;
    }
}

.and-or-operator-div{
    display: flex;
    padding: 10px;
    align-items: center;
}
.group-operator-div{
    padding: 10px;
    display: flex;
    justify-content: end;
}

.filter-item-container{
    // overflow-y: scroll;
    max-height: 520px;
    overflow-x: hidden;
}

.vertical-line-div{
    border: 0;
    border-top: 2px solid #6B859E;
    width: 25%;
    margin: 20px auto;
}

.fields-group-label-value{
    color: #16365F !important;
    font-weight: 800;
    font-size: 14px !important;
}

.field-label-div{
    width: 29%;
}

.add-condition-value-div{
    // padding: 0 10px 10px 10px;
    width: 36%;
}

.add-condition-button-div{
    height: 100%;
    display: flex;
    justify-content: end;
    padding: 10px 10px;
}

.apply-changes-div{
    display: flex;    
    margin-bottom: 10px;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}

.cancel-add-button-div{
    display: flex;
    gap: 10px;
}

.fields-selection{
    width: 100%;
    div{
        padding: 3px 12px 1px 5px !important;
        font-size: 13px !important;
    }
    input{
        padding: 0 !important;
    }
}
.fiels-group-part{
    width: 33%;
}

.condition-group-part{
    width: 25%;
}

.cancel-button-div{
    padding: 4px 19px 0px 0px;
}

.operator-div{
    padding-right: 10px;
}

.multiselect-chip{
    height: 75px;
    overflow-y: auto;
    cursor: pointer;
}

.filter-dropdown{
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 100%;
    word-wrap: break-word;
    width: 50%;
    display: block;
}

.fields-inputs{
    padding: 3px 22px 1px 3px !important;
    font-size: 13px !important;
    word-wrap: break-word;
}

.fields-group-label:hover {
    background-color: #dae1e9;
    cursor: pointer;
  }