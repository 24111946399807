@import "./../../../styles/variables.scss";

.title {
    font-weight: 600 !important;
    font-size: 30px !important ;
    padding-left: 35px !important;
}

.buttons {
    display: flex;
    justify-content: end;
    position: relative;
    top: 10px;
    gap: 20px;
}

.collection-error-div {
    margin: -4% 0px !important;
    padding: 0px !important;
}

.cancel-button {
    color: $link-color !important;
    text-transform: none !important;
    text-decoration: none !important;
    padding: 6px 16px !important;
}

.accept-button {
    background-color: $button-primary !important;
    color: $white !important;
    border-radius: 20px !important;
    text-transform: none !important;
    text-decoration: none !important;
    width: 75px !important;
    font-weight: 600 !important;
}
