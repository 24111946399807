@import "./../../../../styles/variables.scss";

.cloud-data-script-backdrop {
    backdrop-filter: blur(3px);
}

.cloud-data-script-dialog-paper {
    border-radius: 10px;
    height: 80vh;
    overflow-y: unset;
}

.cloud-data-script-paper {
    padding: 20px;
}

.cloud-data-script-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.cloud-data-script-title {
    color: #666;
}

.cloud-data-script-test-button {
    margin-right: 16px;
}

.cloud-data-script-content {
    // display: flex;
    height: calc(100% - 56px);
}

.cloud-data-script-radio-section {
    margin-bottom: 15px;
}

.tab-div {
    width: -webkit-fill-available;
}

.tab-btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}

.test-transform-data-table {
    margin: 20px 0;
    height: 66vh;
}

.merge-predefine-title {
    font-weight: bold !important;
    white-space: nowrap !important;
    color: #16365F !important;
    font-size: 18px !important;
    font-family: unset !important;
}

.merge-predefine-menu {
    padding-left: 24px !important;
    color: #333 !important;
    font-size: 16px !important;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        color: $secondary;
      }
}

.predefine-script-menu-role-div {
    ul {
        display: contents;
    }
  }