@import "./../../../styles/variables.scss";

.refresh-schedule-group-container .MuiButtonGroup-root {
    display: flex;
    width: 100%;
}

.refresh-schedule-group-container .MuiButton-root {
    text-transform: none;
    flex: 1;
    border-color: #cbcbcb;
    padding: 8px 0px 8px 0px;
    color: #16365F;
    font-size: 16px;
}

.refresh-schedule-group-container .MuiButton-root.selected {
    background-color: #dae1e9;
}

.file-url {
    input {
        padding-right: 200px !important;
    }
}

.syncing-radio-button {
    display: flex;
    align-items: center;
    gap: 10px;
}

.radio-button-group {
    display: flex;
}

.schedule-timing-div {
    flex-direction: column;
    gap: 10px;
    overflow-x: auto !important;
    width: 100%;
}

.schedule-timing {
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.disabled-div {
    pointer-events: none;
    opacity: 0.7;
}

.schedule-header{
    font-family: "Poppins";
    font-size: 24px;
    color: $primary;
    font-weight: 700;
    margin-bottom: 8px;
}

.schedule-active-button {
    position: absolute;
    left : 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.schedule-timing [tab-name="MINUTES"] {
    .c-and {
        border: 1px solid #ccc;
        padding: 1rem;
        border-radius: 5px;

        .c-and-item.value-range-1-10 .c-and-item-check {
            display: none;
        }
    }
}

.schedule-timing .c-host {
    width: 100%;
    margin-top: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 325px;

    .form-group {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .c-tab-content {
        margin-top: 10px;
        margin-bottom: 15px;

        .c-segment {
            display: contents !important;

            .c-every-check {
                display: none;
            }

            .c-and-list {
                display: grid !important;
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;
                gap: 5px;
                width: 680px;
                overflow-y: unset !important;
                padding-left: 0 !important;
            }

            .c-and-item {
                width: auto !important;
                padding: 0 !important;
                margin: 0 !important;
            }
        }

        .form-inline {
            display: flex !important;
            gap: 10px;
        }

        .c-and-weekday {
            .c-and-weekday-list {
                display: flex !important;
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                gap: 5px;
                width: 680px;
                overflow-y: unset !important;
                padding-left: 0 !important;
            }
        }

        .c-and-monthday {
            .c-and-monthday-list {
                display: grid !important;
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                gap: 5px;
                width: 680px;
                overflow-y: unset !important;
                padding-left: 0 !important;
            }
        }

        .c-range {
            display: flex !important;
            gap: 10px;
        }
    }

    .c-tabs {
        display: flex;
        list-style: none;
        gap: 20px;
        padding: 0;
        margin: 0;

        .c-tab {
            padding: 8px 16px;
            background-color: #f5f5f5;
            color: #333;
            border: none;
            border-radius: 4px;
            transition: background-color 0.3s, color 0.3s;
        }

        .c-tab:hover {
            background-color: #e0e0e0;
            cursor: pointer;
        }

        .c-tab.active {
            background-color: #e0cc4d;
            color: #fff;
        }

        .c-tab-item {
            text-align: left;
            border: 0;
            border-radius: 0;
        }
    }
}

.file-url-div {
    display: flex;
    align-items: center;
}

.check-connection-button {
    margin-right: -10px;
    position: absolute;
    right: 0;
}