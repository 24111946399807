.connecting-stage-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(107, 133, 158, 0.7); // Semi-transparent overlay
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; // Ensures the overlay is on top of other elements
  }

.connecting-stage-message {
    font-size: 0.8rem;
}

.connecting-screen-asheet-logo {
    display: inline-block;
    svg {
        width: 46px;
        height: 62px;
        margin-right: -0.3rem;
    }
  }

.connecting-screen-connect-button {
    margin-top: 2rem;
}

.arrow-icon-connecting-satge {
 
        font-size: 3.5rem;
        margin-top: 0.5rem;
        margin-left: 1rem;
        height: 50px;
        width: 50px;

}

